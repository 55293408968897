.about {
  margin: 15rem auto;
  max-width: 1200px;
  padding: 0 2rem;
  opacity: 0;
  transform: translateY(40px);
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.about.visible {
  opacity: 1;
  transform: translateY(0);
}

.about_title h3 {
  color: #fff;
  font-family: NTR, sans-serif;
  font-size: 4rem;
  font-weight: 700;
}

.about_title {
  display: flex;
  padding-bottom: 42px;
  width: 100vw;
  opacity: 0;
  animation: fadeInUp 1s ease forwards;
  animation-delay: 1.2s;
}

.about_title::after {
  background-color: #80ffdb50;
  content: "";
  display: block;
  height: 1px;
  margin-left: 20px;
  margin-top: 39px;
  width: 300px;
  opacity: 0;
  animation: fadeInWidth 1s ease forwards;
  animation-delay: 1.4s;
}

.about_content {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  max-width: 1200px;
}

.bio_grid {
  flex: 1;
}

.bio_grid ul {
  display: grid;
  grid-template-columns: repeat(2, minmax(140px, 200px));
  gap: 1rem;
  list-style-type: none;
  padding-top: 20px;
}

.bio_grid li {
  position: relative;
  color: #fff;
}

.stack_item li {
  color: #fff;
  opacity: 0;
  animation: fadeInUp 1s ease forwards;
  animation-delay: 1.2s;
}

.bio_grid li::before {
  color: #80ffdb;
  content: "▹ ";
  left: 0;
}

.about_info {
  color: #fff;
  margin-right: 10%;
  font-size: 1.2rem;
  line-height: 2rem;
  /* font-weight: 500; */
  margin: 17px 0 0 0;
}

.about_info span {
  display: block; /* Ensures each line is treated as a block element */
  /* margin: 0.5rem 0 0 0;  */
}
.about_img {
  position: relative;
  flex: 0 0 400px;
  opacity: 0;
  transform: translateX(20px);
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 400ms;
}

.about.visible .about_img {
  opacity: 1;
  transform: translateX(0);
}

.about_img::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  width: 100%;
  height: 100%;
  border: 2px solid #80ffdb;
  border-radius: 8px;
  z-index: -1;
  transition: all 0.3s ease;
}

.about_img:hover::before {
  top: 10px;
  left: 10px;
}

.about_img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

@media (max-width: 1200px) {
  .about {
    margin: 8rem 2rem 15rem 4rem;
  }

  .about_title h3 {
    font-size: 3.5rem;
  }

  .about_info {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  .about_img {
    flex: 0 0 350px;
  }
}

@media (max-width: 992px) {
  .about {
    margin: 6rem 2rem 12rem 3rem;
  }

  .about_title h3 {
    font-size: 3rem;
  }

  .about_info {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  .about_img {
    flex: 0 0 300px;
  }
}

@media (max-width: 768px) {
  .about {
    margin: 5rem 2rem 10rem 2rem;
  }

  .about_title h3 {
    font-size: 2.5rem;
  }

  .about_content {
    flex-direction: column;
  }

  .about_info {
    margin-right: 0;
  }

  .about_img {
    display: none;
  }

  .bio_grid ul {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
  }
}

@media (max-width: 480px) {
  .about {
    margin: 4rem 1rem 8rem 1rem;
  }

  .about_title h3 {
    font-size: 2rem;
  }

  .about_title::after {
    width: 150px;
    margin-left: 10px;
    margin-top: 20px;
  }

  .about_info {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .bio_grid ul {
    grid-template-columns: repeat(2, minmax(150px, 10px));
    padding-top: 20px;
    gap: 0.5rem;
  }
}

/* Tech stack animations */
.stack_item {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.about.visible .stack_item {
  opacity: 1;
  transform: translateY(0);
}

/* Add transition delays for each stack item */
.stack_item:nth-child(1) {
  transition-delay: 200ms;
}
.stack_item:nth-child(2) {
  transition-delay: 300ms;
}
.stack_item:nth-child(3) {
  transition-delay: 400ms;
}
.stack_item:nth-child(4) {
  transition-delay: 500ms;
}
.stack_item:nth-child(5) {
  transition-delay: 600ms;
}
.stack_item:nth-child(6) {
  transition-delay: 700ms;
}

/* About content animations */
.about_info span {
  display: block;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.about.visible .about_info span {
  opacity: 1;
  transform: translateY(0);
}

.about_info span:nth-child(1) {
  transition-delay: 100ms;
}
.about_info span:nth-child(2) {
  transition-delay: 200ms;
}
.about_info span:nth-child(3) {
  transition-delay: 300ms;
}
