.experience {
  margin: 15rem auto;
  max-width: 1400px;
  padding: 0 4rem;
  opacity: 0;
  transform: translateY(40px);
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.experience.visible {
  opacity: 1;
  transform: translateY(0);
}

.experience_title h3 {
  color: #fff;
  font-family: NTR, sans-serif;
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-weight: 700;
}

.experience_title {
  display: flex;
  padding-bottom: 42px;
  width: 100%;
  margin-bottom: 3rem;
}

.experience_container {
  display: flex;
  gap: 4rem;
  min-height: 400px;
}

.job_tabs {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  border-left: 2px solid rgba(128, 255, 219, 0.3);
}

.job_tab {
  padding: 1.2rem 2rem;
  background: none;
  border: none;
  color: #fff;
  text-align: left;
  cursor: pointer;
  font-size: 1.1rem;
  position: relative;
  transition: all 0.3s ease;
}

.job_tab:hover {
  color: #80ffdb;
  background-color: rgba(128, 255, 219, 0.1);
}

.job_tab.active {
  color: #80ffdb;
}

.job_tab.active::before {
  content: "";
  position: absolute;
  left: -2px;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #80ffdb;
}

.job_content {
  flex: 1;
  padding: 0 2rem;
  max-width: 800px;
  animation: fadeIn 0.5s ease;
}

.job_header {
  margin-bottom: 2rem;
}

.job_header h3 {
  color: #fff;
  font-size: clamp(1.2rem, 3vw, 1.8rem);
  margin-bottom: 0.8rem;
}

.job_header h4 {
  color: #80ffdb;
  font-size: clamp(1rem, 2.5vw, 1.4rem);
  margin-bottom: 0.8rem;
}

.job_duration {
  color: #fff;
  opacity: 0.8;
  font-size: 0.9rem;
}

.job_description {
  list-style: none;
  padding: 0;
}

.job_description li {
  color: #fff;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;
}

.job_description li::before {
  content: "▹";
  position: absolute;
  left: 0;
  color: #80ffdb;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .experience {
    padding: 0 3rem;
  }

  .experience_container {
    gap: 3rem;
  }

  .job_tabs {
    min-width: 220px;
  }
}

@media (max-width: 992px) {
  .experience {
    padding: 0 2rem;
  }

  .experience_container {
    gap: 2rem;
  }

  .job_content {
    padding: 0 1rem;
  }
}

@media (max-width: 768px) {
  .experience {
    padding: 0 1.5rem;
    margin: 10rem auto;
  }

  .experience_container {
    flex-direction: column;
    min-height: 300px;
  }

  .job_tabs {
    flex-direction: row;
    overflow-x: auto;
    border-left: none;
    border-bottom: 2px solid rgba(128, 255, 219, 0.3);
    padding-bottom: 2px;
    min-width: unset;
  }

  .job_tab {
    padding: 1rem 1.5rem;
    white-space: nowrap;
  }

  .job_tab.active::before {
    left: 0;
    bottom: -2px;
    top: auto;
    height: 2px;
    width: 100%;
  }

  .job_content {
    padding: 2rem 0.5rem;
  }

  /* Hide scrollbar but keep functionality */
  .job_tabs {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .job_tabs::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 480px) {
  .experience {
    padding: 0 1rem;
    margin: 8rem auto;
  }

  .job_tab {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
  }

  .job_header h3 {
    font-size: 1.2rem;
  }

  .job_header h4 {
    font-size: 1rem;
  }

  .job_description li {
    font-size: 0.9rem;
    line-height: 1.6;
    margin-bottom: 0.8rem;
  }
}

/* Animation refinements */
.job_description li {
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInUp 0.5s ease forwards;
}

.job_description li:nth-child(1) {
  animation-delay: 0.1s;
}
.job_description li:nth-child(2) {
  animation-delay: 0.2s;
}
.job_description li:nth-child(3) {
  animation-delay: 0.3s;
}
.job_description li:nth-child(4) {
  animation-delay: 0.4s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
