* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* background-color: rgb(59 42 86); */
  background: linear-gradient(243deg, #180026, #170532);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 14s ease infinite;
  -moz-animation: AnimationName 14s ease infinite;
  animation: AnimationName 14s ease infinite;
}

.blur {
  filter: blur(5px);
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 72%;
  }
  50% {
    background-position: 100% 29%;
  }
  100% {
    background-position: 0% 72%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 72%;
  }
  50% {
    background-position: 100% 29%;
  }
  100% {
    background-position: 0% 72%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 72%;
  }
  50% {
    background-position: 100% 29%;
  }
  100% {
    background-position: 0% 72%;
  }
}
