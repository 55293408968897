.home {
  margin: 12rem auto 0;
  max-width: 1200px;
  padding: 0 2rem;
}

.home_name_hi {
  margin: 0 0 35px 4px;
  font-size: clamp(1rem, 2vw, 1.5rem);
  color: #80ffdb;
  opacity: 0;
  animation: fadeInUp 1s ease forwards;
  animation-delay: 0.2s;
}

.home_name {
  font-size: clamp(2.5rem, 5vw, 4.5rem);
  font-weight: 700;
  color: #fff;
  line-height: 1.1;
}

.home_description {
  margin-top: 2rem;
  max-width: 600px;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  color: #fff;
  line-height: 1.5;
  opacity: 0;
  animation: fadeInUp 1s ease forwards;
  animation-delay: 0.8s;
}

.home_button {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  opacity: 0;
  animation: fadeInUp 1s ease forwards;
  animation-delay: 1s;
}

.home_button a {
  text-decoration: none;
}

.home_button button {
  padding: 1rem 2rem;
  background: transparent;
  border: 1px solid #80ffdb;
  border-radius: 4px;
  color: #80ffdb;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.home_button button:hover {
  background: rgba(128, 255, 219, 0.1);
  transform: translateY(-3px);
}

/* Responsive styles */
@media (max-width: 1200px) {
  .home_name {
    margin: 0 0 0 50px;
    font-size: 3.5rem;
  }

  .home_description {
    margin: 20px 0 0 50px;
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .home_name {
    margin: 0 0 0 20px;
    font-size: 2.5rem;
  }

  .home_description {
    margin: 15px 0 0 20px;
    font-size: 1rem;
  }

  .home_button {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .home_name {
    margin: 0 0 0 10px;
    font-size: 2rem;
  }

  .home_description {
    margin: 10px 0 0 10px;
    font-size: 0.9rem;
  }
}

.home_name h1,
.home_name h2 {
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  opacity: 0;
  transform: translateY(20px);
}

.home_name h1 {
  animation: fadeInUp 1s ease forwards;
  animation-delay: 0.4s;
}

.home_name h2 {
  animation: fadeInUp 1s ease forwards;
  animation-delay: 0.6s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Optional: Add hover effect */
.home_name h1:hover,
.home_name h2:hover {
  color: var(--lightest-navy);
  transition: color 0.3s ease;
}
