.icon-container {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 1rem;
  cursor: pointer;
}

.social_icon-list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

.social_icon-list::after {
  content: "";
  display: block;
  width: 2.5px;
  height: 100vh;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
}

.social_icon-list-item img {
  width: 35px;
}

/* Hide mobile container in desktop view */
.mobile-icon-container {
  display: none;
}

@media (max-width: 768px) {
  /* Hide desktop version */
  .icon-container {
    display: none;
  }

  /* Show mobile version */
  .mobile-icon-container {
    display: block;
    padding: 0.5rem;
  }

  .mobile-icon-container .social_icon-list {
    flex-direction: row;
    justify-content: center;
    gap: 1.5rem;
  }

  .mobile-icon-container .social_icon-list::after {
    display: none;
  }

  .mobile-icon-container .social_icon-list-item img {
    width: 25px;
  }
}

.social_icon-list-item {
  opacity: 0;
  animation: fadeInLeft 0.5s ease forwards;
}

.social_icon-list-item:nth-child(1) {
  animation-delay: 1.4s;
}
.social_icon-list-item:nth-child(2) {
  animation-delay: 1.6s;
}
.social_icon-list-item:nth-child(3) {
  animation-delay: 1.8s;
}
.social_icon-list-item:nth-child(4) {
  animation-delay: 2s;
}

.social_icon-list::after {
  opacity: 0;
  animation: fadeInHeight 1s ease forwards;
  animation-delay: 2.2s;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInHeight {
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 100vh;
  }
}

/* For mobile version */
@media (max-width: 768px) {
  .mobile-icon-container .social_icon-list-item {
    animation-name: fadeInUp;
    animation-delay: 0.3s;
  }

  .mobile-icon-container .social_icon-list-item:nth-child(1) {
    animation-delay: 0.3s;
  }
  .mobile-icon-container .social_icon-list-item:nth-child(2) {
    animation-delay: 0.4s;
  }
  .mobile-icon-container .social_icon-list-item:nth-child(3) {
    animation-delay: 0.5s;
  }
  .mobile-icon-container .social_icon-list-item:nth-child(4) {
    animation-delay: 0.6s;
  }
}

.social_icon-list-item a {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.social_icon-list-item a:hover {
  transform: translateY(-3px);
}

.social_icon-list-item img {
  width: 35px;
  transition: filter 0.3s ease;
}

.social_icon-list-item a:hover img {
  filter: brightness(0) saturate(100%) invert(91%) sepia(24%) saturate(803%)
    hue-rotate(89deg) brightness(104%) contrast(103%);
}

@media (max-width: 768px) {
  .mobile-icon-container .social_icon-list-item img {
    width: 25px;
  }

  .mobile-icon-container .social_icon-list {
    gap: 2rem;
  }

  .social_icon-list-item a:hover {
    transform: translateY(-2px);
  }
}
