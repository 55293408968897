.footer {
  margin-top: 9rem;
  padding: clamp(2rem, 5vw, 4rem) 0;
  text-align: center;
  /* background: rgba(128, 255, 219, 0.05); */
  /* border-top: 1px solid rgba(128, 255, 219, 0.1); */
}

.footer_content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 clamp(1rem, 5vw, 4rem);
}

.footer p {
  color: #ffffff9d;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  line-height: 1.6;
}

.copyright {
  display: block;
  margin-top: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
}

/* Responsive styles */
@media (min-width: 768px) {
  .footer p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .copyright {
    display: inline;
    margin-top: 0;
    position: relative;
  }

  .copyright::before {
    content: "•";
    margin-right: 1rem;
    color: #80ffdb;
  }
}

@media (max-width: 480px) {
  .footer {
    margin-top: 4rem;
    padding: 2rem 1rem;
  }
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.footer {
  animation: fadeIn 0.5s ease forwards;
}
