.projects {
  margin: 15rem auto;
  max-width: 1400px;
  padding: 0 4rem;
  /* opacity: 0; */
  transform: translateY(40px);
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.projects.visible {
  opacity: 1;
  transform: translateY(0);
}

.projects_title h3 {
  color: #fff;
  font-family: NTR, sans-serif;
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-weight: 700;
}

.projects_title {
  display: flex;
  padding-bottom: 42px;
  width: 100%;
  margin-bottom: 3rem;
}

.project_cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 400px), 1fr));
  gap: clamp(1rem, 3vw, 2rem);
  width: 100%;
}

.project_card {
  background: rgba(128, 255, 219, 0.05);
  border: 1px solid rgba(128, 255, 219, 0.1);
  border-radius: 8px;
  padding: clamp(1.5rem, 3vw, 2rem);
  display: flex;
  /* flex-direction: column; Activate this when you add a live demo link" */
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-height: clamp(280px, 40vh, 320px);
}

.project_card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px -15px rgba(128, 255, 219, 0.2);
}

.project_card-content h3 {
  color: #80ffdb;
  font-size: clamp(1.25rem, 2.5vw, 1.5rem);
  margin-bottom: clamp(0.8rem, 2vw, 1rem);
}

.project_card-content p {
  color: #fff;
  font-size: clamp(0.9rem, 1.5vw, 1rem);
  line-height: 1.6;
  margin-bottom: clamp(1rem, 2vw, 1.5rem);
  white-space: pre-wrap;
}

.project_tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(0.5rem, 1vw, 1rem);
  margin-bottom: clamp(1rem, 2vw, 1.5rem);
}

.project_tech-stack span {
  color: #fff;
  font-size: clamp(0.8rem, 1.5vw, 0.9rem);
  padding: 0.3rem 0.8rem;
  background: rgba(128, 255, 219, 0.1);
  border-radius: 15px;
}

.project_card-footer {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: auto;
  padding-top: 1rem;
}

.github-icon {
  width: clamp(20px, 4vw, 24px);
  height: clamp(20px, 4vw, 24px);
  transition: transform 0.3s ease;
}

.github-icon:hover {
  transform: translateY(-2px);
}

.live-link {
  color: #80ffdb;
  text-decoration: none;
  font-size: clamp(0.8rem, 1.5vw, 0.9rem);
  transition: color 0.3s ease;
}

.live-link:hover {
  color: #fff;
}

/* Responsive Breakpoints */
@media (min-width: 1400px) {
  .project_cards-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1200px) {
  .projects {
    padding: 0 3rem;
  }

  .project_cards-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 992px) {
  .projects {
    padding: 0 2.5rem;
    margin: 12rem auto;
  }
}

@media (max-width: 768px) {
  .projects {
    padding: 0 2rem;
    margin: 10rem auto;
  }

  .project_cards-grid {
    grid-template-columns: 1fr;
    max-width: 600px;
    margin: 0 auto;
  }

  .project_card {
    min-height: 260px;
  }
}

@media (max-width: 480px) {
  .projects {
    padding: 0 1rem;
    margin: 8rem auto;
  }

  .project_card {
    padding: 1.25rem;
    min-height: 240px;
  }

  .projects_title {
    margin-bottom: 2rem;
  }
}

/* Animation refinements */
.project_card {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
}

.project_card:nth-child(1) {
  animation-delay: 0.1s;
}
.project_card:nth-child(2) {
  animation-delay: 0.2s;
}
.project_card:nth-child(3) {
  animation-delay: 0.3s;
}
.project_card:nth-child(4) {
  animation-delay: 0.4s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
