.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 4rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: all 0.3s ease;
}

.header.scrolled {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  padding: 1rem 4rem;
  box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.3);
}

.logo {
  color: #80ffdb;
  font-size: 1.5rem;
  font-weight: bold;
  z-index: 100;
  cursor: pointer;
}

.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  background: #000;
  height: 100vh;
  padding: 2rem;
  transform: translateX(100%);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.nav.active {
  transform: translateX(0);
}

.nav_close {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
  margin-left: auto;
  width: 2rem;
  margin-bottom: 4rem;
}

.nav_close-line {
  width: 100%;
  height: 2px;
  background: #fff;
  transition: transform 0.3s ease;
}

.nav_close-line:first-child {
  transform: rotate(45deg) translate(5px, 5px);
}

.nav_close-line:last-child {
  transform: rotate(-45deg) translate(5px, -5px);
}

.nav_list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.nav_list-item a {
  color: #fff;
  text-decoration: none;
  font-size: clamp(2rem, 4vw, 2.5rem);
  cursor: pointer;
  transition: color 0.3s ease;
}

.nav_list-item a:hover {
  color: #80ffdb;
}

.nav_social {
  margin-top: auto;
  padding-bottom: 2rem;
}

.hamburger {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
  z-index: 90;
}

.line {
  width: 2rem;
  height: 2px;
  background: #fff;
  transition: transform 0.3s ease;
}

.hamburger.active .line:first-child {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.active .line:nth-child(2) {
  opacity: 0;
}

.hamburger.active .line:last-child {
  transform: rotate(-45deg) translate(5px, -5px);
}

@media (min-width: 769px) {
  .hamburger {
    display: none;
  }

  .nav {
    position: static;
    background: none;
    height: auto;
    padding: 0;
    transform: none;
  }

  .nav_close {
    display: none;
  }

  .nav_list {
    flex-direction: row;
    gap: 2rem;
  }

  .nav_list-item a {
    font-size: 1rem;
  }

  .nav_social {
    display: none;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 1.5rem;
  }

  .header.scrolled {
    padding: 1rem 1.5rem;
  }

  .nav_social {
    display: flex;
    justify-content: center;
  }

  .nav_social .social-icons {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    position: static;
    padding: 0;
  }

  .nav_social .social-icons a {
    font-size: 1.5rem;
    writing-mode: horizontal-tb;
  }

  .nav_social .social-icons::after {
    display: none;
  }
}
