.main {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  transition: filter 0.3s ease;
}

.main.blur {
  filter: blur(5px);
  pointer-events: none;
}

@media (max-width: 1200px) {
  .main {
    max-width: 1000px;
  }
}

@media (max-width: 768px) {
  .main {
    padding: 0 1rem;
  }
}
